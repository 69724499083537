import React, { createContext, useContext, useReducer } from "react";
import Reducer from './Reducer';

const initialState = {
    isLogged: false,
    loading: false,
    jwt: null
};
export const Context = createContext(initialState);

/* Store with context*/
const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};
export default Store;