import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Store from "./Reducers/Store";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import config from "./app.json";
import React from 'react';
import ReactDOM from 'react-dom/client';
import "./Assets/Css/index.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import WithAxios from "./WithAxios";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Store>
    <WithAxios>
      <App />
    </WithAxios>
  </Store>
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
