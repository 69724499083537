import config from "../app.json";
import axios from "axios";

import { Context } from "../Reducers/Store";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import AlertComponent from "../Components/AlertComponent";
import { Container, Row, Col, Button } from "react-bootstrap";
import Pagination from "../Components/Pagination";
import FieldText from "../Components/Fields/FieldText";
import FieldUploadMultipart from "../Components/Fields/FieldUploadMultipart";

const Home = () => {
    var navigate = useNavigate();

    const [state, dispatch] = useContext(Context);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploads, setUploads] = useState([]);
    const [conversions, setConversions] = useState([]);
    const conversionsRef = useRef(conversions);

    useEffect(() => {
        conversionsRef.current = conversions;
    }, [conversions]);

    useEffect(() => {
        files?.map(file => {
            let conversion = conversions.filter(x => x.guidFile == file.guidFile).at(0);
            if (conversion == null) {
                startConversion(file);
            }
        });
    }, [files]);

    const startConversion = (file) => {
        axios.post("tools/startConversion", {
            guidFile: file.guidFile
        }).then((res) => {
            setConversions(currentConversions => [...currentConversions, {
                guidFile: file.guidFile,
                progress: 0,
                status: "Waiting"
            }]);
            setTimeout(() => statusCheck(file.guidFile), 1000);
        }).catch(function (error) {
            return AlertComponent("Error", error?.response?.data?.error || error?.message, { icon: `error` });
        });
    }

    const statusCheck = (guidFile) => {
        axios.get("tools/getConversion/?guidFile=" + guidFile).then((res) => {
            if (res.data) {
                setConversions(current => conversionsRef.current.map(u => u.guidFile === guidFile ? { ...u, progress: res.data.progress, status: res.data.status, error: res.data.error } : u));

                if (res.data.status != "Ready" && res.data.status != "Error")
                    setTimeout(() => statusCheck(guidFile), 1000);
            }
            else
                setTimeout(() => statusCheck(guidFile), 1000);
        }).catch(function (error) {
            return AlertComponent("Error", error?.response?.data?.error || error?.message, { icon: `error` });
        });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h2>Convert PDF To PNG</h2>
                    <FieldUploadMultipart
                        handleCompleted={setFiles}
                        handleUpdates={setUploads}
                        accept={["application/pdf"]}
                        maxSize={250 * 1024 * 1024}
                        className="upload-video-w-progress upload-button"
                        files={files}
                        customPreview={true}
                    />
                    <hr />
                    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                        {uploads?.map((upload, index) => {
                            const conversion = conversions.filter(x => x.guidFile == upload.uploadModel?.guidFile).at(0);
                            const progress = conversion != null ? conversion.progress : upload.progress;
                            return (
                                <div key={index}>
                                    <div>File: {upload.file?.name}</div>
                                    {!upload.error && !conversion?.error && <div>{conversion?.status ?? "Uploading"}: {progress}%</div>}
                                    {upload.error && <div>Error: {upload.error.message}</div>}
                                    {conversion?.error && <div>Error: {conversion.error}</div>}

                                    <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                        <div style={{ backgroundColor: (upload.error || conversion?.error ? 'red' : '#eee'), flex: 1, height: '40px', borderRadius: 20, overflow: 'hidden' }}>
                                            <div style={{ backgroundColor: '#00f', width: `${progress}%`, height: '100%' }}></div>
                                        </div>
                                        <div>
                                            <button className="btn btn-success" disabled={conversion == null || conversion.status != "Ready"} onClick={() => window.location.href = "tools/download/?guidFile=" + conversion.guidFile + "&name=" + upload.file?.name}>Download</button></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
