import { Context } from "../Reducers/Store";
import React, { useContext } from "react";

function Loading() {
    const [state, dispatch] = useContext(Context);

    return (<>
        {state.loading &&
            <div style={{
                width: '100%',
                height: '100%',
                position: 'fixed',
                paddingTop: "20%",
                zIndex: 9999,
                top: 0,
                left: 0,
                backgroundColor: 'rgba(11, 63, 155, 0.9)',
                color: "#fff",
                fontSize: 14,
                textAlign: "center"
            }}>
                <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"/>
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        }</>
    );
}
export default Loading;