import React, { useState, useContext, useEffect } from "react";
import { Context } from "../Reducers/Store";

import { Outlet } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import Loading from '../Components/Loading';
import Logo from "../Assets/Images/logo7.svg"
import Avatar from "../Assets/Images/system-avatar.png"
import { Link } from 'react-router-dom';

const Layout = () => {
    const [state, dispatch] = useContext(Context);
    return (
        <div className="App">
            <Navbar expand="lg" className="bp-navbar">
                <Navbar.Brand href="#home" as={Link} to="/"><img className="logo" src={Logo} style={{ height: "50%" }} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" drop="left">
                    <Nav className="ms-auto">  {/* Alterado de ml-auto para ms-auto */}
                        <Nav.Link as={Link} to="/" style={{ color: "#FFF" }}>Convert PDF to PNG</Nav.Link>
                        <Nav.Link as={Link} to="/resize" style={{ color: "#FFF" }}>Resize Image</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Outlet />
            <Loading />
        </div >
    );
}
export default Layout;