let loadingCount = 0;

function Reducer(state, action) {
  if (Array.isArray(action)) {
    action.forEach((x) => {
      state = Reducer(state, x);
    });
    return state;
  } else {
    switch (action.type) {
      case "SET_READY":
        return { ...state, ready: action.payload };
      case "SET_LOGGED":
        let isLogged = false;
        let jwt = null;

        // Set the user data...
        if (action.payload != null) {
          isLogged = true;
          jwt = action.payload;

          localStorage.setItem("jwt", jwt);
        } else {
          localStorage.removeItem("jwt");
        }

        return {
          ...state,
          isLogged: isLogged,
          jwt: jwt
        };

      case "SET_LOADING":
        loadingCount += (action.payload ? 1 : -1);
        let currentLoading = action.payload;

        if (loadingCount < 0)
          loadingCount = 0;

        if (!currentLoading && loadingCount > 0)
          currentLoading = true;

        return { ...state, loading: currentLoading };
      default:
        return state;
    }
  }
}

export default Reducer;
