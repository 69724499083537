import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function NotFound() {
    return (
        <div>
            <p>Oops... This page doesn't exists!</p>
            <Link to="/">Click here to back</Link>
        </div>
    );
}

export default NotFound;
