import React, { useState, useRef, useEffect } from "react";
import _uniqueId from "lodash/uniqueId";
import { Upload } from 'tus-js-client';
import "../../Assets/Css/Components/FieldUploadMultipart.scss";

export default function FieldUploadMultipart({ leftToRight, className, style, disabled, label, accept, maxSize, handleCompleted, handleUpdates, files, customPreview }) {
    const [id] = useState(_uniqueId("field_"));
    const [uploads, setUploads] = useState(Array.from(files ?? []).map(f => ({
        file: { name: f.name, type: f.type, size: f.size, },
        progress: 100,
        uploading: false,
        error: null,
        success: true,
        uploadModel: f
    })));
    const [completed, setCompleted] = useState(files ?? []);

    const inputRef = useRef(null);
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (handleCompleted)
            handleCompleted(completed);

    }, [completed]);

    useEffect(() => {
        if (handleUpdates)
            handleUpdates(uploads);

    }, [uploads]);

    const handleFilesChange = (files) => {
        const newUploads = Array.from(files).map(file => ({
            file,
            progress: 0,
            uploading: false,
            error: null,
            success: false,
            uploadModel: null,
        }));

        setUploads(currentUploads => [...currentUploads, ...newUploads]);
        startUploads(newUploads);
    };

    const startUploads = (uploads) => {
        uploads.forEach((upload, index) => {
            const file = upload.file;

            if (accept && !accept.includes(file.type) && !accept.includes(file.type.split('/')[0] + "/*"))
                return setUploads(current => current.map(u => u.file === file ? { ...u, error: { message: "Invalid file type." }, uploading: false } : u));

            if (maxSize && maxSize != Infinity && maxSize < file.size)
                return setUploads(current => current.map(u => u.file === file ? { ...u, error: { message: "File " + file.name + " (" + (file.size / 1024 / 1024).toFixed(0) + " mb) is larger than max size limit (" + (maxSize / 1024 / 1024).toFixed(0) + " mb)." }, uploading: false } : u));

            const tusUpload = new Upload(file, {
                endpoint: 'tus',
                retryDelays: [0, 3000, 5000, 10000, 20000],
                chunkSize: 5 * 1024 * 1024,
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                },
                onError: function (error) {
                    setUploads(current => current.map(u => u.file === file ? { ...u, error, uploading: false } : u));
                },
                onProgress: function (bytesUploaded, bytesTotal) {
                    const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                    setUploads(current => current.map(u => u.file === file ? { ...u, progress: percentage } : u));
                },
                onSuccess: function () {
                    let guid = tusUpload.url.split('/').at(-1);
                    guid = guid.substring(0, 8) + "-" + guid.substring(8, 12) + "-" + guid.substring(12, 16) + "-" + guid.substring(16, 20) + "-" + guid.substring(20, 32);

                    const uploadModel = {
                        "name": file.name,
                        "extension": file.name.split('.').at(-1),
                        "type": file.type,
                        "size": file.size,
                        "guidFile": guid
                    };

                    setCompleted([...completed, uploadModel]);
                    setUploads(current => current.map(u => u.file === file ? { ...u, success: true, uploading: false, uploadModel: uploadModel } : u));
                },
            });

            tusUpload.start();
            setUploads(current => current.map(u => u.file === file ? { ...u, uploading: true } : u));
        });
    };

    const handleAbort = (file) => {
        // Implement abort logic based on the file
    };

    return (
        <div className={"form-group upload-multipart " + (leftToRight ? "upload-multipart-leftToRight " : " ") + className} style={{ ...(style ?? {}), opacity: disabled ? 0.5 : 1 }}>
            {label && <label htmlFor={id}>{label}</label>}
            <div className="upload-multipart-selection">
                <div className="upload-multipart-selection-input">
                    <input type="file" multiple onChange={e => handleFilesChange(e.target.files)} ref={inputRef} disabled={disabled} />
                </div>
            </div>

            {!customPreview && uploads.map((upload, index) => {
                return (
                    <div key={index}>
                        <div>Uploading: {upload.file.name}</div>
                        <div>Progress: {upload.progress}%</div>
                        {upload.error && <div>Error: {upload.error.message}</div>}
                        <div style={{ backgroundColor: '#eee', width: '100%', height: '20px' }}>
                            <div style={{ backgroundColor: '#00f', width: `${upload.progress}%`, height: '100%' }}></div>
                        </div>
                        {upload.uploading && <button onClick={() => handleAbort(upload.file)}>Abort</button>}
                    </div>
                )
            })}
        </div>
    );
}
/*


import config from "../../app.json";

import React, { useState, useEffect, useRef } from "react";
import _uniqueId from "lodash/uniqueId";
import { Upload } from 'tus-js-client';
import "../../Assets/Css/Components/FieldUploadMultipart.scss";

export default function FieldUploadMultipart({
    label,
    information,
    style,
    required = false,
    disabled = false,
    className = "",
    error,
    maxSize = 2048000000,
    accept = ["image/*", "video/*"],
    onUploadComplete,
    onUploadError,
    onProgress,
    onStateChanged,
    data,
    leftToRight = false,
    customPreview = null
}) {
    const [id] = useState(_uniqueId("field_"));
    const [localError, setLocalError] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadModel, setUploadModel] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(null);
    const upload = useRef(null);

    const inputRef = useRef(null);

    const clearFile = () => {
        setFile(null);
        setUploading(false);
        inputRef.current.value = "";
    }

    const handleAbort = () => {
        if (upload.current) {
            upload.current.abort(true);
            clearFile();
        }
    }

    const handleUpload = () => {
        // Verifique se o MIME type do arquivo está na lista válida
        if (accept) {
            if (!accept.includes(file.type) && !accept.includes(file.type.split('/')[0] + "/*")) {
                setLocalError("Invalid file type.");
                clearFile();
                return;
            }
        }

        if (maxSize && maxSize != Infinity && maxSize < file.size) {
            setLocalError("File " + file.name + " (" + (file.size / 1024 / 1024).toFixed(0) + " mb) is larger than max size limit (" + (maxSize / 1024 / 1024).toFixed(0) + " mb).");
            clearFile();
            return;
        }

        setLocalError(null);

        // Create a new tus upload
        upload.current = new Upload(file, {
            endpoint: 'tus',
            retryDelays: [0, 3000, 5000, 10000, 20000],
            chunkSize: 5 * 1024 * 1024,
            metadata: {
                filename: file.name,
                filetype: file.type,
            },
            onError: function (error) {
                setUploading(false);
                setLocalError(error);
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                setProgress(percentage);
            },
            onSuccess: function () {
                let guid = upload.current.url.split('/').at(-1);
                guid = guid.substring(0, 8) + "-" + guid.substring(8, 12) + "-" + guid.substring(12, 16) + "-" + guid.substring(16, 20) + "-" + guid.substring(20, 32);

                setUploadModel({
                    "name": upload.current.file.name,
                    "extension": upload.current.file.name.split('.').at(-1),
                    "type": upload.current.file.type,
                    "size": upload.current.file.size,
                    "guidFile": guid
                });

                setUploading(false);
            },
        })

        // Check if there are any previous uploads to continue.
        upload.current.findPreviousUploads().then(function (previousUploads) {
            // Found previous uploads so we select the first one.
            if (previousUploads.length)
                upload.current.resumeFromPreviousUpload(previousUploads[0])

            // Start the upload
            setUploading(true);
            upload.current.start()
        });
    }

    useEffect(() => {
        if (data) {
            setUploadModel(data);
        }
    }, [data])

    useEffect(() => {
        if (file) {
            handleUpload();
        }
    }, [file]);

    useEffect(() => {
        if (onUploadError && localError)
            onUploadError(localError);
    }, [localError]);

    useEffect(() => {
        if (uploadModel && uploadModel.size && file) {
            if (onUploadComplete)
                onUploadComplete(uploadModel);
        }
    }, [uploadModel]);

    useEffect(() => {
        if (onStateChanged)
            onStateChanged(uploading);
    }, [uploading]);

    useEffect(() => {
        if (onProgress)
            onProgress(progress);
    }, [progress]);

    return (
        <div className={"form-group upload-multipart " + (leftToRight ? "upload-multipart-leftToRight " : " ") + + className} style={{ ...(style ?? {}), opacity: disabled ? 0.5 : 1 }}>
            {label && <label htmlFor={id}>{label}</label>}
            <div className="upload-multipart-selection">
                <div className="upload-multipart-selection-input">
                    <input type="file" id={id} multiple={true} onChange={x => setFile(x.target.files[0])} ref={inputRef} required={required} disabled={uploading || disabled} />
                </div>
                {customPreview && uploadModel && <div className="upload-multipart-custom-preview">{customPreview(uploadModel)}</div>}
                {!customPreview && uploadModel && <div className="upload-multipart-file-name"><small>{uploadModel?.name}</small></div>}
            </div>
            {information ? (
                <small id={id + "Help"} className="form-text text-muted">
                    {information}
                </small>
            ) : null}
            {uploading && <div style={{ marginTop: 10, display: "flex", flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'space-around' }}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, flexGrow: 1 }}>
                    <small style={{ fontWeight: 'bold' }}>Uploading: {progress}%</small>
                    <div style={{ backgroundColor: '#070721', width: '100%', height: 10, borderRadius: 10, overflow: "hidden" }}>
                        <div style={{ backgroundColor: '#44CD45', width: progress + "%", height: 10 }}>

                        </div>
                    </div>
                </div>
                <button className={`btn btn-danger`} type="button" onClick={handleAbort} style={btnRemove}>
                    Abort
                </button>
            </div>}
            {(error || localError) && <div className="invalid-feedback" style={{ display: "block" }}>
                <ul>
                    {error && <li>{error}</li>},
                    {localError && <li>{localError}</li>}
                </ul>
            </div>}
        </div>
    );
}

export const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
};

export const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    boxSizing: "border-box",
    borderRadius: 10,
    overflow: "hidden",
};

export const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
};

const btnRemove = {
    fontSize: '10pt'
};
*/