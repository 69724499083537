import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const AlertComponent = (
  title,
  mess,
  props,
  btnFunc,
  showAsToast = true,
  btnTxt = "OK"
) => {
  const MySwal = withReactContent(Swal);
  let propsFixed = props ?? {};

  let toastProps = {};
  if (showAsToast) {
    toastProps = {
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      icon: "success",
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    };
  }

  if (mess == "Due to inactivity, your session has expired. Please log back in.") {
    propsFixed.icon = "warning";
    title = "Session Expired";

    if (toastProps.icon == "success") {
      toastProps.icon = "warning";
      toastProps.title = "Session Expired";
    }
  }

  let args = {
    ...toastProps,
    ...propsFixed,
  };

  args.title = title;
  args.text = mess;
  if (btnTxt) args.confirmButtonText = btnTxt;

  return MySwal.fire(args).then((result) => {
    if (btnFunc) btnFunc(result);
  });
};

export default AlertComponent;
