import config from "../app.json";
import axios from "axios";

import { Context } from "../Reducers/Store";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import AlertComponent from "../Components/AlertComponent";
import { Container, Row, Col, Button } from "react-bootstrap";
import Pagination from "../Components/Pagination";
import FieldText from "../Components/Fields/FieldText";
import FieldUploadMultipart from "../Components/Fields/FieldUploadMultipart";

const Resize = () => {
    var navigate = useNavigate();

    const [state, dispatch] = useContext(Context);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploads, setUploads] = useState([]);

    return (
        <Container>
            <Row>
                <Col>
                    <h2>Resize Images</h2>
                    <FieldUploadMultipart
                        handleCompleted={setFiles}
                        handleUpdates={setUploads}
                        accept={["image/png"]}
                        maxSize={250 * 1024 * 1024}
                        className="upload-video-w-progress upload-button"
                        files={files}
                        customPreview={true}
                    />
                    <hr />
                    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                        {uploads?.map((upload, index) => {
                            return (
                                <div key={index}>
                                    <div>File: {upload.file?.name}</div>
                                    {!upload.error && <div> {upload.progress == 100 ? "Ready" : "Uploading"}: {upload.progress}%</div>}
                                    {upload.error && <div>Error: {upload.error.message}</div>}

                                    <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                        <div style={{ backgroundColor: (upload.error ? 'red' : '#eee'), flex: 1, height: '40px', borderRadius: 20, overflow: 'hidden' }}>
                                            <div style={{ backgroundColor: '#00f', width: `${upload.progress}%`, height: '100%' }}></div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                            <button className="btn btn-success" disabled={upload.progress != 100} onClick={() => window.location.href = "tools/downloadResize/?guidFile=" + upload.uploadModel.guidFile + "&size=1280&name=" + upload.file.name}>Download 1280px</button>
                                            <button className="btn btn-success" disabled={upload.progress != 100} onClick={() => window.location.href = "tools/downloadResize/?guidFile=" + upload.uploadModel.guidFile + "&size=5120&name=" + upload.file.name}>Download 5120px</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Resize;
