import { Context } from "./Reducers/Store";
import React, { useEffect, useContext } from "react";
import Layout from "./Pages/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";

// General
import NotFound from "./Pages/NotFound";
import Home from "./Pages/Home";
import Resize from "./Pages/Resize";

function App() {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    var jwt = localStorage.getItem("jwt");
    console.log(jwt);
    if (jwt != null) {
      dispatch([{ type: "SET_LOGGED", payload: jwt }, { type: "SET_READY", payload: true }]);
    }
    else {
      dispatch({ type: "SET_READY", payload: true });
    }
  }, [0]);

  return state.ready ? (

    <BrowserRouter basename="/">
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/resize" element={<Resize />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  ) : null;
}

export default App;
