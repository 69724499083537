import React, { useContext } from "react";
import axios from 'axios';
import { Context } from "./Reducers/Store";

/* Axios Interceptors */
const WithAxios = ({ children }) => {
    const [state, dispatch] = useContext(Context);

    // Ignored endpoints for loading
    const checkIgnore = (url) => {
        return url.indexOf("/getConversion") == -1 &&
            url.indexOf("/startConversion") == -1;
    }

    if (global._interceptor1 != null)
        axios.interceptors.request.eject(global._interceptor1);

    global._interceptor1 = axios.interceptors.request.use(
        request => {
            if (state.jwt != null)
                request.headers.Authorization = 'Bearer ' + state.jwt;

            if (checkIgnore(request.url))
                dispatch({ type: 'SET_LOADING', payload: true });

            return request;
        }, error => {
            return Promise.reject(error);
        }
    );

    if (global._interceptor2 != null)
        axios.interceptors.response.eject(global._interceptor2);

    global._interceptor2 = axios.interceptors.response.use(
        response => {
            if (checkIgnore(response.config.url))
                dispatch({ type: 'SET_LOADING', payload: false });
            return response
        },
        error => {
            if (checkIgnore(error.config.url))
                dispatch({ type: 'SET_LOADING', payload: false });
            var errorParsed = JSON.parse(JSON.stringify(error));

            // Reject promise if usual error
            if (errorParsed.status == "401") {
                if (errorParsed.response == null || errorParsed.response.data == null || (errorParsed.response.data != "INVALID_EMAIL" && errorParsed.response.data != "BLOCKED_USER" && errorParsed.response.data != "USER_NOT_AUTHORIZED" && errorParsed.response.data != "INVALID_PASSWORD")) {
                    dispatch([{ type: "SET_LOGGED", payload: null }]);
                    error.message = "Due to inactivity, your session has expired. Please log back in.";
                }
            }

            return Promise.reject(error);
        }
    );

    return children
}

export default WithAxios;